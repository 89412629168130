import { createContext, useState, useContext } from "react";

 const DateChange = createContext();
 console.log(DateChange)

 function DateChangeContext( {children} ) {
    // console.log(children)
    // const [selectionRange, setSelectionRange] = useState({ startDate: Date.now(),
    // endDate: Date.now(),
    // key: 'selection'});
    // const [selectionRange, setSelectionRange] = useState({ startDate: "test"});
    const [selectionRange, setSelectionRange] = useState({ startDate: Date.now(),
    endDate: Date.now(),
    key: 'selection'});
    console.log(selectionRange)
    return (
    // children ? <DateChange.Provider value={ {selectionRange,  setSelectionRange }}>
    <DateChange.Provider value={ {selectionRange,  setSelectionRange }}>

       {/* { children.children  }
        </DateChange.Provider>: [<div></div>] */}
          { children}
        </DateChange.Provider>
    
    );
};


 const DateRangeState = () => {
  return useContext(DateChange);
};

export {DateChangeContext, DateChange, DateRangeState}
