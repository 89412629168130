import { Route, Redirect } from "react-router-dom";
import React, {Suspense, lazy, Fragment} from 'react';

import {
    ToastContainer,
} from 'react-toastify';

const Dashboards = lazy(() => import('../../Pages/Dashboards'));
const Login = lazy(() => import('../../Pages/Login'));


const AppMain = () => {

    return (
        <Fragment>

            {/* Components */}


            {/* Charts */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Charts examples
                            <small>Because this is a demonstration we load at once all the Charts examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
            </Suspense>

            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboards examples
                            <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
            <Route path="/dashboards" component={Dashboards}/>
            </Suspense>
            <Suspense fallback={
                <div>Loading... </div>}>           
                <Route path="/login" component={Login}/>  
            </Suspense>

            {/* <Route exact path="/" render={() => (
                <Redirect to="/dashboards/main"/>
            )}/> */}
             <Route exact path="/" render={() => (
                <Redirect to="/login"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;